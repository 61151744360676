import { assign, setup, fromPromise, fromCallback } from 'xstate'

import {
  concatUnique,
  SearchKind,
  Results,
  ResultMedia,
  fetchData
} from './search.utils'

import { proxyUrl, getMediaUrl } from './database'

type Playable = 'waiting' | 'ok' | 'bad'
type DurOk = {
  duration: number
  playable: Playable
}
type Locals = Record<string, DurOk>

export type SearchContext = {
  error?: Error | string
  searchOpen: boolean
  searchTerm: string
  searchKind: SearchKind
  locals: Locals,
  total?: number
  next?: string
  results: Results
}

type SetSearchOpen = { type: 'SET_SEARCH_OPEN', searchOpen: boolean }
type SetSearchTerm = { type: 'SET_SEARCH_TERM', searchTerm: string }
type SetSearchKind = { type: 'SET_SEARCH_KIND', searchKind: SearchKind }
type AppendSearchLocals = { type: 'SEARCH_APPEND_LOCALS', locals: Locals }
type SearchIt = { type: 'SEARCH_IT', searchKind: SearchKind, searchTerm: string }

export type SearchEvents =
  | { type: 'SEARCH_MORE' }
  | { type: 'SEARCH_CLEAR' }
  | { type: 'DONE' }
  | SearchIt
  | AppendSearchLocals
  | SetSearchTerm
  | SetSearchKind
  | SetSearchOpen

const serial = (funcs: Array<() => Promise<Playable>>) =>
  funcs.reduce((promise, func) =>
    promise
      .then(result => func()
        .then(list => [list].concat(result))
      ), Promise.resolve([] as Array<Playable>))

const checkMedia = (media: ResultMedia, a: HTMLAudioElement, controller: AbortController, send: (s: unknown) => void) => new Promise((resolve) => {
  getMediaUrl(controller.signal)(media)
    .then(({ src, isBlob, crossOrigin }) => {
      console.log('checkMedia', src, isBlob, crossOrigin)
      if (!src) return resolve({})
      if (isBlob) {
        window.URL.revokeObjectURL(src) // need to revoke blob url
        const l = { [media.uri]: { duration: media.duration, playable: 'ok' } }
        send({ type: 'SEARCH_APPEND_LOCALS', locals: l })
        return resolve(l)
      }
      if (controller.signal.aborted) {
        const l = { [media.uri]: { duration: media.duration, playable: 'waiting' } }
        send({ type: 'SEARCH_APPEND_LOCALS', locals: l })
        return resolve(l)
      }
      function onLoad() {
        const l = { [media.uri]: { duration: isFinite(a.duration) ? a.duration : media.duration, playable: 'ok' } }
        send({ type: 'SEARCH_APPEND_LOCALS', locals: l })
        return resolve(l)
      }
      function onErr() {
        const onErrProxy = () => {
          console.log('errored on', media.uri)
          const l = { [media.uri]: { duration: media.duration, playable: 'bad' } }
          send({ type: 'SEARCH_APPEND_LOCALS', locals: l })
          resolve(l)
        }
        a.onerror = onErrProxy
        console.log('checking proxy src', src)
        a.src = proxyUrl(src)
      }
      a.onloadedmetadata = onLoad
      a.onerror = onErr

      a.crossOrigin = crossOrigin
      a.preload = 'metadata'
      a.src = src
    })
    .catch(() => {
      const l = { [media.uri]: { duration: media.duration, playable: 'bad' } }
      send({ type: 'SEARCH_APPEND_LOCALS', locals: l })
      resolve(l)
    })
})

const searchIt = fromPromise(({ input }: { input: { searchTerm: string, searchKind: SearchKind } }) => fetchData(input.searchTerm, input.searchKind))
const searchMore = fromPromise(({ input }: { input: { searchTerm: string, searchKind: SearchKind, next: string } }) =>
  fetchData(input.searchTerm, input.searchKind, input.next))

const searchCheckMedia = fromCallback<SearchEvents, { results: Results, locals: Locals }>(({ input, sendBack }) => {
  const a = new window.Audio()
  const controller = new AbortController()
  const funcs = input.results
    .reduce((acc, r) => {
      r.media.forEach(m => {
        if (!input.locals[m.uri] || input.locals[m.uri].playable === 'waiting') {
          // @ts-expect-error wtf
          acc.push(() => checkMedia(m, a, controller, sendBack))
        }
      })
      return acc
    }, [])
  serial(funcs)
    .catch(console.error)
  return () => {
    controller.abort()
  }
})

export const SearchMachine = setup({
  types: {
    context: {} as SearchContext,
    events: {} as SearchEvents
  },
  actions: {
    setSearchOpen: assign({
      searchOpen: ({ event }) => (event as SetSearchOpen).searchOpen
    }),
    setSearchTerm: assign({
      searchTerm: ({ event }) => (event as SetSearchTerm).searchTerm
    }),
    setSearchKind: assign({
      searchKind: ({ event }) => (event as SetSearchKind).searchKind,
      searchOpen: false
    }),
    appendLocals: assign({
      locals: ({ context, event }) => Object.assign({}, context.locals, (event as AppendSearchLocals).locals)
    }),
    clearResults: assign({
      error: undefined,
      searchTerm: '',
      total: 0,
      next: undefined,
      results: []
    }),
  },
  actors: {
    searchIt,
    searchMore,
    searchCheckMedia
  }
}).createMachine({
  context: ({ input }) => input as SearchContext,
  on: {
    DONE: { target: '.done' },
    SET_SEARCH_OPEN: { actions: 'setSearchOpen' },
    SET_SEARCH_TERM: { actions: 'setSearchTerm' },
    SET_SEARCH_KIND: { actions: 'setSearchKind' },
    SEARCH_CLEAR: { actions: 'clearResults' },
    SEARCH_APPEND_LOCALS: { actions: 'appendLocals' },

    SEARCH_IT: { target: '.searching' },
    SEARCH_MORE: { target: '.searchMore' }
  },
  id: 'SearchMachine',
  initial: 'idle',
  states: {
    done: { type: 'final' as "final" },
    idle: {
      invoke: {
        src: 'searchCheckMedia',
        input: ({ context }) => ({ results: context.results, locals: context.locals }),
        // onError: { actions: 'setPlaylistError' }
      }
    },
    searching: {
      tags: 'loading',
      entry: ['setSearchTerm', 'setSearchKind'],
      invoke: {
        src: 'searchIt',
        input: ({ context }) => ({ searchKind: context.searchKind, searchTerm: context.searchTerm }),
        onDone: {
          actions: assign({
            error: () => undefined,
            total: ({ event }) => event?.output?.total || 0,
            next: ({ event }) => event?.output?.next,
            results: ({ event }) => event?.output?.results || []
          }),
          target: 'idle'
        },
        onError: {
          actions: assign({ error: ({ event }) => event.error as Error }),
          target: 'idle'
        }
      }
    },
    searchMore: {
      tags: 'loading',
      invoke: {
        src: 'searchMore',
        input: ({ context }) => ({ searchKind: context.searchKind, searchTerm: context.searchTerm, next: context.next || '' }),
        onDone: {
          actions: assign({
            next: ({ event }) => event?.output?.next,
            results: ({ context, event }) => concatUnique(context.results, event?.output?.results)
          }),
          target: 'idle'
        },
        onError: {
          actions: assign({ error: ({ event }) => event.error as Error }),
          target: 'idle'
        }
      }
    }
  }
})
