import {
  Participants,
  Participant,
  GoldenColor,
  AudioConstraints,
  ExtendedDeviceInfo,
} from './types'


const offset = Math.random() * 360
const goldenAngle = (n: number) => offset + n * 137.508
const selectColor = (n: number) => `hsl(${goldenAngle(n)},50%,15%)`
const selectBgColor = (n: number) => `hsl(${goldenAngle(n)},50%,85%)`

let count = 0
export const getColors = (): GoldenColor => {
  const fg = selectColor(count)
  const bg = selectBgColor(count)
  count += 1
  return { fg, bg }
}
export const getSetColors = (participants: Participants, p: Partial<Participant>): Partial<Participant> => {
  const tmp = participants.get(p.feed || "unknown")
  return tmp?.colors
    ? Object.assign(tmp, p)
    : Object.assign(p, { colors: getColors() })
}

export const parseMsg = (msg: string) => {
  try {
    return JSON.parse(msg)
  } catch (e) {
    return {}
  }
}


function dec2hex(dec: number) {
  return dec.toString(16).padStart(2, '0')
}

// generateId :: Integer -> String
export const generateId = (len: number): string => {
  const arr = new Uint8Array((len || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

// note: this needs to be a function, not const
function getProcessBase() {
  // @ts-expect-error: Property 'env' does not exist on type 'ImportMeta'. [2339]
  return (import.meta.env.VITE_SERVER_PROCESS || window.location.host)
}

// note: this needs to be a function, not const
export function getProcessHttp() {
  const u = getProcessBase()
  return u.slice(0, 9) === 'localhost'
    ? 'http://' + u + '/' // because we are not behind nginx when in local dev mode
    : 'https://' + u + '/process/'
}
export const getProcessWs = () => {
  const u = getProcessBase()
  return u.slice(0, 9) === 'localhost'
    ? 'ws://' + u + '/janode'
    : 'wss://' + u + '/janode'
}

const processProxyUri = getProcessHttp() + 'proxy/'

// @ts-expect-error: Property 'env' does not exist on type 'ImportMeta'. [2339]
export const getProxyUri = () => import.meta.env.VITE_PROXY_URL
  // @ts-expect-error: [2339]
  ? import.meta.env.VITE_PROXY_URL
  : processProxyUri


export const getInputDevices = (): Promise<Map<string, ExtendedDeviceInfo>> => navigator && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices
  ? navigator.mediaDevices.enumerateDevices()
    .then(devs => new Map(devs
      .filter(d => d.deviceId && d.kind === 'audioinput')
      .map(d => [d.deviceId, d.toJSON()])
    ))
  : Promise.reject(new Error('Your browser does not support enumerateDevices'))


const doWithStream = (constraints: AudioConstraints, deviceId?: string) => async (mediaStream: MediaStream) => {
  const track = mediaStream.getAudioTracks().find(x => x)
  const capabilities = track && track.getCapabilities
    ? track.getCapabilities()
    : {}
  const settings = track && track.getSettings
    ? track.getSettings()
    : {}

  const supportedConstraints = navigator?.mediaDevices?.getSupportedConstraints() || {} as MediaTrackSupportedConstraints
  console.log('supportedConstraints', supportedConstraints)
  console.log('capabilities', capabilities)
  console.log('--- tried for', deviceId, constraints)
  console.log('--- got settings', settings)
  return getInputDevices()
    .then(inDevices => {
      const did = settings && settings.deviceId
        ? settings.deviceId
        : null
      if (did && inDevices.get(did)) { // it can happen that we get
        const d = Object.assign({}, inDevices.get(did), { capabilities, settings })
        const devices = new Map(inDevices.set(did, d))
        return { mediaStream, devices, constraints, supportedConstraints, deviceId: did }
      }
      return Promise.reject(new Error(`Problem with deviceId ${deviceId}, not found in device list ${did}`))
    })
}

export type GetAudioReturnData = {
  mediaStream: MediaStream,
  devices: Map<string, ExtendedDeviceInfo>,
  constraints?: AudioConstraints,
  supportedConstraints?: MediaTrackSupportedConstraints
  deviceId: string
}
type GetAudioReturn = Promise<GetAudioReturnData>

type GetAudioStreamArgs = {
  constraints?: AudioConstraints
  deviceId?: string
  mediaStream?: MediaStream
}
export const getAudioStream = ({
  constraints = {},
  deviceId,
  mediaStream
}: GetAudioStreamArgs): GetAudioReturn => {
  return navigator?.mediaDevices?.getUserMedia
    ? navigator.mediaDevices.getUserMedia({
      video: false,
      audio: deviceId && constraints
        ? Object.assign({}, constraints, { latency: 0, deviceId: { exact: deviceId }, sampleRate: 48000, channelCount: 2 })
        : true // { latency: 0 }
    })
      .then(doWithStream(constraints, deviceId))
      .catch(e => {
        if (e.name !== 'NotReadableError') throw e
        if (mediaStream) {
          console.log('firefox bug, dumping old stream')
          // work around firefox bug https://github.com/twilio/twilio-video.js/issues/1309
          // https://stackoverflow.com/questions/59068146/navigator-mediadevices-getusermedia-api-rejecting-with-error-notreadableerror
          // https://bugzilla.mozilla.org/show_bug.cgi?id=1238038
          mediaStream.getTracks().forEach(t => {
            t.stop()
            mediaStream.removeTrack(t)
          })
          return getAudioStream({ constraints, deviceId })
        }
        throw e
      })
    : Promise.reject(new Error('Your browser does not support getUserMedia'))
}

// currently, chrome does not allow you to call applyConstraints on audio.
// it works in firefox and safari.  https://bugs.chromium.org/p/chromium/issues/detail?id=796964
export const setTrackConstraints = (stream: MediaStream, constraints: AudioConstraints, deviceId: string): GetAudioReturn => {
  const track = stream.getAudioTracks().find(x => x)
  console.log('setting track constraints', track, constraints)
  return track
    ? track.applyConstraints(constraints)
      .then(() => doWithStream(constraints, deviceId)(stream))
    : Promise.reject(new Error('Could not find track'))
}

const jsonOk = (r: Response) => (json: JSON) => r.ok
  ? json
  : Promise.reject(new Error(r.statusText))

export const postJson = (url: string, data: unknown): Promise<unknown> => window.fetch(url, {
  method: 'POST', // *GET, POST, PUT, DELETE, etc.
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(data) // body data type must match "Content-Type" header
})
  .then(r => r.json()
    .then(jsonOk(r))
  )

