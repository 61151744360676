import { assign, setup, fromPromise, enqueueActions, assertEvent, ActorRefFrom } from 'xstate'
import { arrayMove } from '@dnd-kit/sortable'
import { MoveMediaEvent, MoveMediaArgs } from './main.machine'
import { playerMachine } from './player.machine'
import { AnnounceDlKeys } from './download.machine'
import { mapBufKeysToPlaylist } from './database'

import db, {
  Media,
  addFilesToPlaylist,
  addMediaAtIndex
} from './database'


type Playlist = Array<Media>
export type PlaylistContext = {
  error?: string
  parentRef: ActorRefFrom<typeof playerMachine>
  loopPlaylist: boolean
  trackId: string
  playlist: Playlist
  playlistId: string
}

export const scrubPlaylist = (playlist: Playlist, index: number) => playlist.map((i, idx) => idx === index
  ? Object.assign(i, { isQueued: true })
  : Object.assign(i, { isQueued: false })
)

export type LoadMedia = { type: 'LOAD_MEDIA', media: Media }
export type LoadIdx = { type: 'LOAD_IDX', idx: number }
export type PlaylistOrder = { type: 'PLAYLIST_ORDER', playlist: Playlist }
export type PlaylistSetAsyncEvent = { data: { playlist: Playlist } }

type PlaylistRemove = { type: 'PLAYLIST_REMOVE', id: string }
type PlaylistAdd = { type: 'PLAYLIST_ADD', idx?: number, media: Playlist }
type PlaylistConcat = { type: 'PLAYLIST_CONCAT' } & MoveMediaArgs
type PlaylistSave = { type: 'PLAYLIST_SAVE', playlistId: string, playlist: Playlist, display: string }
type PlaylistAddFiles = { type: 'PLAYLIST_ADD_FILES', idx?: number, files: FileList | Array<File> }

export type PlaylistEvents = LoadIdx
  | MoveMediaEvent
  | PlaylistAddFiles
  | PlaylistRemove
  | PlaylistAdd
  | PlaylistConcat
  | { type: 'PLAYLIST_LOAD', playlistId: string }
  | PlaylistSave
  | PlaylistOrder
  | AnnounceDlKeys
  | { type: 'DONE' }

const setPlaylist = (ctxPl: Playlist, outPl: Playlist) => ctxPl.length
  ? outPl
  : scrubPlaylist(outPl, 0)

export const PlaylistMachine = setup({
  types: {
    context: {} as PlaylistContext,
    events: {} as PlaylistEvents,
  },
  actions: {
    loadFirst: enqueueActions(({ context, enqueue }) => {
      console.log('loadFirst', context.playlist.length, context.playlist.find(m => m.isQueued))
      // this needs to be safe...only load something if we don't have anything queued
      if (context.playlist.length <= 1) {
        return enqueue.raise({ type: 'LOAD_IDX', idx: 0 })
      }
      if (!context.playlist.find(m => m.isQueued)) {
        return enqueue.raise({ type: 'LOAD_IDX', idx: 0 })
      }
    }),
    loadQueued: enqueueActions(({ context, enqueue }) => {
      // on first time load
      return enqueue.raise({ type: 'LOAD_IDX', idx: context.playlist.findIndex(m => m.isQueued) || 0 })
    }),
    orderPlaylist: assign({
      playlist: ({ event }) => (event as PlaylistOrder).playlist
    }),
    removeFromPlaylist: assign({
      playlist: ({ context, event }) => context.playlist.filter((m) => m.id !== (event as PlaylistRemove).id)
    }),
    concatToPlaylist: assign({
      playlist: ({ context, event }) => {
        assertEvent(event, 'PLAYLIST_CONCAT')
        if (!event.dragInfo.media?.id) {
          return context.playlist
        }
        // we are dropping on the track itself
        return context.playlist
          .filter(m => m.id !== event.dragInfo.media?.id)
          .concat(event.dragInfo.media)
      }
    }),
    moveMedia: enqueueActions(({ context, event, enqueue }) => {
      assertEvent(event, 'MOVE_MEDIA')
      // const event = e as MoveMediaEvent
      if (event.overInfo.trackId === context.trackId) {
        if (event.overInfo.plItemId) {
          const oldIndex = (context.playlist || []).findIndex(m => m.id === event.dragInfo.plItemId)
          const newIndex = (context.playlist || []).findIndex(m => m.id === event.overInfo.plItemId)
          const playlist = arrayMove((context.playlist || []), oldIndex, newIndex)
          // re-ordering will save the list
          return enqueue.raise({ type: 'PLAYLIST_ORDER', playlist } as PlaylistOrder)
        } else if (event.dragInfo.media) {
          return enqueue.raise({
            type: 'PLAYLIST_ORDER',
            playlist: context.playlist
              .filter(m => m.id !== event.dragInfo.media?.id)
              .concat(event.dragInfo.media)
          } as PlaylistOrder)
        }
      }
      return []
    }),
    loadIndex: enqueueActions(({ context, event, enqueue }) => {
      const idx = (event as LoadIdx).idx
      const playlist = context.playlist && context.playlist[(event as LoadIdx).idx]
        ? scrubPlaylist(context.playlist, (event as LoadIdx).idx)
        : context.playlist

      enqueue.assign({ playlist })
      enqueue.sendTo(context.parentRef, { type: 'LOAD_MEDIA', media: playlist[idx] })
    }),
    loadPrev: enqueueActions(({ context, enqueue }) => {
      const idxCurrent = context.playlist.findIndex(i => i.isQueued)
      const idx = context.playlist[idxCurrent - 1]
        ? idxCurrent - 1
        : context.playlist.length - 1
      const playlist = scrubPlaylist(context.playlist, idx)
      enqueue.assign({ playlist })
      enqueue.sendTo(context.parentRef, { type: 'LOAD_MEDIA', media: playlist[idx] })
    }),
    loadNext: enqueueActions(({ context, enqueue }) => {
      const idxCurrent = context.playlist.findIndex(i => i.isQueued)
      const idx = context.playlist[idxCurrent + 1]
        ? idxCurrent + 1
        : 0
      const playlist = scrubPlaylist(context.playlist, idx)
      enqueue.assign({ playlist })
      enqueue.sendTo(context.parentRef, { type: 'LOAD_MEDIA', media: playlist[idx] })
    }),
  },
  actors: {
    addFilesToPlaylist: fromPromise(({ input }: { input: { playlistId: string, files: FileList | Array<File> } }) =>
      addFilesToPlaylist(input.playlistId, input.files)
        .then(playlist => ({ playlist }))),
    addToPlaylist: fromPromise(({ input }: { input: { playlistId: string, media: Playlist, idx?: number } }) =>
      addMediaAtIndex(input.playlistId, input.media, input.idx)
        .then(playlist => ({ playlist }))),
    loadPlaylist: fromPromise(({ input }: { input: { playlistId: string } }) => db.playlists.getById(input.playlistId)
      .then(pl => ({ playlist: pl?.media || [] }))),
    // careful here. we debounce to savePlaylist and the event doesn't come through
    // instead we assume the context as the playlist at that point in time
    savePlaylist: fromPromise(({ input }: { input: { playlistId: string, playlist: Playlist, display: string } }) => {
      return db.playlists.save(input.playlistId, input.playlist, input.display)
      // return db.playlists.save(event.playlistId || context.playlistId, event.playlist || context.playlist, event.display)
    })
  }

}).createMachine({
  context: ({ input }) => input as PlaylistContext,
  on: {
    DONE: { target: '.done' },
    MOVE_MEDIA: { actions: 'moveMedia' },

    PLAYLIST_CONCAT: { actions: ['concatToPlaylist', 'loadFirst'], target: '.savePlaylist' },
    PLAYLIST_REMOVE: { actions: ['removeFromPlaylist', 'loadFirst'], target: '.savePlaylist' },

    PLAYLIST_ADD: { target: '.addToPlaylist' },
    PLAYLIST_ADD_FILES: { target: '.addFilesToPlaylist' },
    // we order the playlist and save it to context on the player.def
    // we save it to db here
    PLAYLIST_ORDER: { actions: 'orderPlaylist', target: '.savePlaylistDebounced' },

    PLAYLIST_SAVE: { target: '.savePlaylist' },
    PLAYLIST_LOAD: { target: '.loadPlaylist' },
    LOAD_PREV: {
      actions: 'loadPrev',
    },
    LOAD_NEXT: {
      actions: 'loadNext',
    },
    LOAD_IDX: {
      actions: 'loadIndex',
    },
    ANNOUNCE_DL_KEYS: {
      actions: assign({
        playlist: ({ context, event }) => mapBufKeysToPlaylist(context.playlist, event.keys)
      })
    }

  },
  id: 'PlaylistMachine',
  initial: 'loadPlaylist',
  states: {
    done: { type: 'final' as "final" },
    idle: {
    },
    pause: {
      tags: ['adding'],
      after: {
        310: {
          target: 'idle'
        }
      }
    },
    addFilesToPlaylist: {
      invoke: {
        src: 'addFilesToPlaylist',
        input: ({ context, event }) => {
          assertEvent(event, 'PLAYLIST_ADD_FILES')
          return { playlistId: context.playlistId, files: event.files }
        },
        onDone: {
          actions: [
            assign({ playlist: ({ context, event }) => setPlaylist(context.playlist, event.output.playlist) }),
            'loadFirst'
          ],
          target: 'idle'
        },
        onError: {
          actions: assign({ error: ({ event }) => String(event.error) }),
          target: 'idle'
        }
      }
    },
    addToPlaylist: {
      invoke: {
        src: 'addToPlaylist',
        input: ({ context, event }) => {
          assertEvent(event, 'PLAYLIST_ADD')
          return { playlistId: context.playlistId, media: event.media, idx: event.idx }
        },
        onDone: {
          actions: [
            assign({ playlist: ({ context, event }) => setPlaylist(context.playlist, event.output.playlist) }),
            'loadFirst'
          ],
          target: 'idle'
        },
        onError: {
          actions: assign({ error: ({ event }) => String(event.error) }),
          target: 'idle'
        }
      }
    },
    loadPlaylist: {
      invoke: {
        src: 'loadPlaylist',
        input: ({ context }) => ({ playlistId: context.playlistId }),
        onDone: {
          actions: [
            assign({ playlist: ({ context, event }) => setPlaylist(context.playlist, event.output.playlist) }),
            'loadFirst'
          ],
          target: 'idle'
        },
        onError: {
          actions: assign({ error: ({ event }) => String(event.error) }),
          target: 'idle'
        }
      }
    },
    savePlaylistDebounced: {
      after: { 300: { target: 'savePlaylist' } }
    },
    savePlaylist: {
      invoke: {
        src: 'savePlaylist',
        input: ({ context, event }) => {
          assertEvent(event, 'PLAYLIST_SAVE')
          return { playlistId: context.playlistId, playlist: context.playlist, display: event.display }
        },
        onDone: { target: 'idle' },
        onError: {
          actions: assign({ error: ({ event }) => String(event.error) }),
          target: 'idle'
        }
      }
    }
  },
})
