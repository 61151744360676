import { assign, forwardTo, sendTo, cancel, fromCallback, fromPromise, setup, enqueueActions, assertEvent, sendParent } from 'xstate'
import { RtcSend, RtcMachine, RtcJoined, RtcConfigured, RtcLeave } from './rtc.machine'
import { TrackInfo, ProcessInfo, AudioInfo, IceArgs } from './types'
import { parseMsg, postJson, getProcessHttp } from './utils'

const SGID = 'sendGuiShare'

export type ChatDataRx = {
  id: string
  display: string
  timestamp: number
  text: string
  attachments?: Array<unknown>
}

type ChatDataTx = {
  id: string
  display: string
  text: string
  attachments?: Array<unknown>
}
/*
type JanusDataTx = {
  id: string
  timestamp: number
}
*/
export type TracksInfoList = Array<TrackInfo>
export type RemoteMixer = Array<TrackInfo>
export type RemoteMixers = Record<string, Array<TrackInfo>>

export type WsContext = {
  chat: Array<ChatDataRx>
  retryDuration: number
  ws?: WebSocket
  error?: Error
  wsurl: string
  feed?: string // the id of the participant
  display?: string // the display of the participant
  remoteMixers: RemoteMixers
  localStream: MediaStream
  room?: string
  secret?: string

  processInfo: Record<string, ProcessInfo>
  recordingInfo: Record<string, number>
}

const urlBase = getProcessHttp()
type FetchInfoInput = {
  input: {
    broadcastId: string
    secret: string
  }
}

export type FetchInfoOutput = {
  processInfo: Record<string, ProcessInfo>
  recordingInfo: Record<string, number>
}

type SetSecret = { type: 'SET_SECRET', secret: string }
export type IcecastCreate = { type: 'ICECAST_CREATE', audio: AudioInfo, icecast: IceArgs }
export type IcecastDestroy = { type: 'ICECAST_DESTROY', audio_port: number, audio_stream: number }

type WsSetChat = { type: 'WS.SET_CHAT', chat: Array<ChatDataRx> }
type SetWs = { type: 'WS.SET_WS', data: WebSocket }
type SetRemoteMixers = { type: 'WS.SET_REMOTE_MIXERS', mixers: RemoteMixers }
export type SendChatEvent = { type: 'WS.SEND_CHAT', data: ChatDataTx }
export type SendJanusEvent = { type: 'WS.SEND_JANUS', data: unknown }
export type SendTracksEvent = { type: 'WS.SEND_TRACKS', data: TracksInfoList }

export type WsEvents = { type: 'WS.CLOSE' }
  | { type: 'WS.ERROR', error: Error }
  | SetWs
  | WsSetChat
  | SetRemoteMixers
  | SendChatEvent
  | SendJanusEvent
  | SendTracksEvent
  | RtcSend
  | RtcJoined
  | RtcConfigured
  | RtcLeave
  | { type: 'RECORD_INFO_START' }
  | { type: 'RECORD_INFO_STOP' }
  | IcecastCreate
  | IcecastDestroy
  | SetSecret

const wsStartup = fromPromise(({ input }: { input: { wsurl: string } }) => new Promise((resolve, reject) => {
  console.log('at startup, conecting to', input.wsurl)
  const ws = new window.WebSocket(input?.wsurl)
  const onError = (e: Event) => {
    ws.removeEventListener('open', onOpen)
    return reject(e)
  }
  function onOpen() {
    ws.removeEventListener('error', onError)
    return resolve(ws)
  }
  ws.addEventListener('error', onError, { once: true }) // If true, the listener would be automatically removed when invoked
  ws.addEventListener('open', onOpen, { once: true })
}))
type WsConnectedRcvEvents = SendTracksEvent | SendChatEvent
const wsConnected = fromCallback<WsConnectedRcvEvents, { ws: WebSocket | undefined }>(({ sendBack, receive, input }) => {
  const ws = input.ws
  if (!ws) return
  const onClose = () => sendBack({ type: 'WS.CLOSE' })
  const onError = () => sendBack({ type: 'WS.ERROR' })

  const onMessage = (event: { data: string }) => {
    const d = parseMsg(event.data)
    console.log('incoming websocket msg ', d)
    if (d.chat) {
      sendBack({ type: 'WS.SET_CHAT', chat: d.chat })
    } else if (d.mixers) {
      sendBack({ type: 'WS.SET_REMOTE_MIXERS', mixers: d.mixers })
    } else {
      const { msg, ...rest } = d
      console.log('got RTC.' + msg.replace(/-/g, '_').toUpperCase(), rest)
      // sendBack({ type: 'RTC.SEND', data: { type: 'RTC.' + msg.replace(/-/g, '_').toUpperCase(), ...rest } })
      sendBack({ type: 'RTC.' + msg.replace(/-/g, '_').toUpperCase(), ...rest })
    }
  }
  receive((e) => {
    console.log('outgoing websocket:', e.type, e.data)
    if (ws && e.type === 'WS.SEND_TRACKS') {
      return ws.send(JSON.stringify({ tracks: e.data }))
    }
    if (e.type === 'WS.SEND_CHAT' && !(e.data?.display && e.data?.id)) {
      return console.error(new Error('no id or display'))
    }
    ws.send(JSON.stringify(e.data))
  })
  ws.addEventListener('message', onMessage)
  ws.addEventListener('close', onClose)
  ws.addEventListener('error', onError)
  return () => {
    ws.removeEventListener('message', onMessage)
    ws.removeEventListener('close', onClose)
    ws.removeEventListener('error', onError)
    ws.close()
  }
})

export const WebsocketMachine = setup({
  types: {
    context: {} as WsContext,
    events: {} as WsEvents,
    children: {} as {
      rtcMachine: 'RtcMachine'
    }
  },
  actors: {
    wsStartup,
    wsConnected,
    RtcMachine,
    fetchInfo: fromPromise(({ input: { broadcastId, secret } }: FetchInfoInput) =>
      postJson(urlBase + 'info/', { broadcastId, secret }) as Promise<FetchInfoOutput>
    )
  },
  actions: {
    setFeed: assign({ feed: ({ event }) => (event as RtcConfigured | RtcJoined).feed }),
    setDisplay: assign({ display: ({ event }) => (event as RtcConfigured | RtcJoined).display }),
    clearFeed: assign({ feed: () => undefined }),
    clearDisplay: assign({ feed: () => undefined }),
    setChat: assign({
      chat: ({ event }) => (event as WsSetChat).chat
    }),
    setRemoteMixers: assign({
      remoteMixers: ({ event }) => (event as SetRemoteMixers).mixers
    }),
    resetRetry: assign({ retryDuration: () => 500 }),
    setRetry: assign({
      retryDuration: ({ context }) => context.retryDuration * 2
    }),
    cancelTracksEvent: cancel(SGID),
    debounceTracksInfo: sendTo('wsConnected', ({ event }) => ({
      type: 'WS.SEND_TRACKS',
      data: (event as SendTracksEvent).data
    }),
      { delay: 500, id: SGID }
    ),

    rtcRtpFwdList: sendTo('wsConnected', ({ context }) => ({
      type: 'WS.SEND_JANUS',
      data: {
        msg: 'rtp-fwd-list',
        data: {
          room: context.room,
          secret: context.secret
        }
      }
    })),
    setSecret: assign({
      secret: ({ event }) => (event as SetSecret).secret
    }),
    createIcecast: sendTo('wsConnected', ({ context, event }) => {
      assertEvent(event, 'ICECAST_CREATE')
      return {
        type: 'WS.SEND_JANUS',
        data: {
          msg: 'icecast-create',
          audio: event.audio,
          icecast: event.icecast,
          room: context.room,
          secret: context.secret
        }
      }
    }),
    destroyIcecast: sendTo('wsConnected', ({ context, event }) => {
      assertEvent(event, 'ICECAST_DESTROY')
      return {
        type: 'SEND_JANUS',
        data: {
          msg: 'icecast-destroy',
          audio_port: event.audio_port,
          audio_stream: event.audio_stream,
          room: context.room,
          secret: context.secret
        }
      }
    })

  },
  delays: {
    RETRY: ({ context }) => context.retryDuration
  }
}).createMachine({
  id: 'websocket',
  initial: 'startup',
  context: ({ input }) => input as WsContext,
  states: {
    startup: {
      invoke: {
        id: 'wsStartup',
        src: 'wsStartup',
        input: ({ context: { wsurl } }) => ({ wsurl }),
        onDone: {
          target: 'connected',
          actions: assign({
            ws: ({ event }) => {
              console.log('ws connected')
              return event.output as WebSocket
            }
          })
        },
        onError: {
          target: 'disconnected',
          actions: assign(({ context }) => {
            if (context.ws) context.ws.close()
            return { ws: undefined }
          })
        }
      }
    },
    disconnected: {
      id: "disconnected",
      after: {
        RETRY: {
          actions: 'setRetry',
          target: 'startup'
        }
      }
    },
    connected: {
      type: 'parallel',
      states: {
        control: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                RECORD_INFO_START: { target: 'fetchInfo' },
              }
            },
            fetchInfo: {
              entry: 'rtcRtpFwdList', // in rtc.machine.ts 
              invoke: {
                src: 'fetchInfo',
                input: ({ context }) => ({ urlBase, broadcastId: context.room || '', secret: context.secret || '' }),
                onDone: {
                  target: 'waiting',
                  actions: assign({
                    recordingInfo: ({ event }) => event.output.recordingInfo,
                    processInfo: ({ event }) => event.output.processInfo
                  })
                },
                onError: {
                  target: 'idle'
                  // actions: (_, event) => console.log('fetchInfo error', event),
                }
              },
              on: {
                RECORD_INFO_STOP: { target: 'idle' }
              }
            },
            waiting: {
              after: {
                2000: {
                  target: 'fetchInfo'
                }
              },
              on: {
                RECORD_INFO_STOP: { target: 'idle' }
              }
            }
          },
          on: {
            ICECAST_CREATE: { actions: 'createIcecast' },
            ICECAST_DESTROY: { actions: 'destroyIcecast' }
          }

        },
        websocket: {
          entry: 'resetRetry',
          invoke: [{
            src: 'wsConnected',
            id: 'wsConnected',
            input: ({ context: { ws } }) => ({ ws }),
          }, {
            src: 'RtcMachine',
            id: 'rtcMachine',
            input: ({ context: { localStream } }: { context: WsContext }) => {
              return {
                room: 'mezcal',
                expected_loss: 0,
                prebuffer: 30,
                bitrate: 64000,
                bitrateSend: 64000,
                quality: 10,
                localStream,
                participants: new Map(),
                forwards: new Map()
              }
            },
          }],
          on: {
            'WS.CLOSE': '#disconnected',
            'WS.ERROR': '#disconnected',
            'WS.SET_CHAT': { actions: 'setChat' },
            'WS.SET_REMOTE_MIXERS': { actions: 'setRemoteMixers' },
            // we pass it on up, this one only comes from rtc.machine
            'RTC.SET_REMOTE_STREAM': { actions: sendParent(({ event }) => event) },

            'RTC.*': { // we forward all events from WS to the child rtc
              actions: [
                enqueueActions(({ event, enqueue }) => {
                  if (event.type === 'RTC.LEAVE') {
                    enqueue('clearFeed')
                  } else if (['RTC.CONFIGURED', 'RTC.JOINED'].includes(event.type)) {
                    enqueue('setFeed')
                    enqueue('setDisplay')
                  }
                }),
                forwardTo('rtcMachine')
              ]
            },
            // only send to ws when connected
            // WS.SEND_CHAT: { actions: forwardTo('wsConnected') },
            'WS.SEND_CHAT': {
              actions: sendTo('wsConnected', ({ context, event }) => ({
                type: 'WS.SEND_CHAT',
                data: Object.assign(event.data, {
                  id: context.feed,
                  // room: context.room,
                  display: context.display
                })
              }))
            },
            'WS.SEND_JANUS': { actions: forwardTo('wsConnected') },
            'WS.SEND_TRACKS': {
              actions: [
                'cancelTracksEvent',
                'debounceTracksInfo'
              ]
            }
          }
        }
      }
    }
  },
  on: {
    SET_SECRET: { actions: 'setSecret' }
  }
})
