import {
  IDBPCursorWithValue,
  DBSchema,
  openDB,
  deleteDB
} from 'idb'

import { getProcessHttp, getProxyUri, generateId } from '../utils'

// @ts-expect-error: Property 'env' does not exist on type 'ImportMeta'. [2339]
export const searchUrl = import.meta.env.VITE_SEARCH
  // @ts-expect-error: [2339]
  ? import.meta.env.VITE_SEARCH.replace(/\/$/, "") // remove trailing slash for robustness
  : ''
console.log('searchUrl', searchUrl)

type YtTempUrl = {
  uri: string
  tempTime: number // for youtube, urls only last 6 hours
  tempUrl: string // youtube temp url
}

type PreviewMedia = {
  uri: string
  tempUrl?: string // youtube temp url
}

export type Media = {
  id: string,
  uri: string
  isQueued: boolean
  hasBuffer: boolean
  type?: string // mime
  description?: string
  duration?: number // in seconds
  size?: number // in bytes
  title?: string
  artist?: string
  album?: string
  tags?: Array<string>
  tempUrl?: string // youtube temp url
  imageUrl?: string // youtube temp url
}

export type MediaBuffer = {
  uri: string,
  type: string // mime
  createdOn: Date
  buffer: ArrayBuffer // we don't save Blob because iOS doesn't support it
  description?: string
  duration?: number // in seconds
  title?: string
}

export type Playlist = {
  id: string
  display?: string
  createdOn: Date
  modifiedOn: Date
  media: Array<Media>
}

export interface DBv3 extends DBSchema {
  'media': {
    key: string
    value: Media & { buffer: ArrayBuffer }
    indexes: {
      name: string
      size: number
      duration: number
      type: string
      description: string
      title: string
      artist: string
      album: string
      tags: Array<string>
    }
  },
  'buffers': {
    key: string
    value: MediaBuffer
    indexes: {
      uri: string
    }
  }
  'playlists': {
    key: string
    value: Playlist
    indexes: {
      id: string
      display: string
      createdOn: Date
      modifiedOn: Date
    }
  }
}

interface DBv4 extends DBSchema {
  'media': {
    key: string
    value: Media & { buffer: ArrayBuffer }
    indexes: {
      name: string
      size: number
      duration: number
      type: string
      description: string
      title: string
      artist: string
      album: string
      tags: Array<string>
    }
  },

  'yttempurls': {
    key: string
    value: YtTempUrl
    indexes: {
      uri: string
    }
  }

  'buffers': {
    key: string
    value: MediaBuffer
    indexes: {
      uri: string
    }
  }
  'playlists': {
    key: string
    value: Playlist
    indexes: {
      id: string
      display: string
      createdOn: Date
      modifiedOn: Date
    }
  }
}

export const dbName = 'mezcal'

const getDb = () => openDB<DBv4>(dbName, 4, {
  async upgrade(db, oldVersion, newVersion) {
    console.log('db version', newVersion, 'upgrading from', oldVersion)
    if (oldVersion === 1 && newVersion === 2) {
      console.log('deleting object store playlist')
      db.deleteObjectStore('playlists')
      db.deleteObjectStore('media')
    }

    if (oldVersion === 3 && newVersion === 4) {
      console.log('deleting object store playlist AND media')
      deleteDB(dbName)
    }

    if (!db.objectStoreNames.contains('playlists')) {
      const playlists = db.createObjectStore('playlists', { keyPath: 'id' })
      playlists.createIndex('id', 'id', { unique: true })
      playlists.createIndex('display', 'display', { unique: false })
      playlists.createIndex('createdOn', 'createdOn', { unique: false })
      playlists.createIndex('modifiedOn', 'modifiedOn', { unique: false })
    }
    if (!db.objectStoreNames.contains('buffers')) {
      const buffers = db.createObjectStore('buffers', { keyPath: 'uri' })
      buffers.createIndex('uri', 'uri', { unique: true })
    }
    if (!db.objectStoreNames.contains('yttempurls')) {
      const ytt = db.createObjectStore('yttempurls', { keyPath: 'uri' })
      ytt.createIndex('uri', 'uri', { unique: true })
    }
  },
  blocked() {
    console.error('db blocked')
  },
  blocking() {
    console.error('db blocking')
  },
  terminated() {
    console.error('db blocking')
  }
})

const dbPromise = getDb()


const processProxyUri = getProcessHttp() + 'proxy/'

export const proxyUrl = (uri: string): string => {
  try {
    const u = new URL(uri)
    const p = u.port && ['80', '443'].indexOf(u.port) === -1
      ? processProxyUri + '?url=' + encodeURIComponent(uri) // use our local proxy since cloudflare cannot fetch non 80/443 ports
      : getProxyUri() + '?url=' + encodeURIComponent(uri)
    return p
  } catch (e) {
    console.error('could not suss out url:', e)
    return getProxyUri() + '?url=' + encodeURIComponent('https://badurl.com')
  }
}

export const canProxy = (uri: string): boolean => {
  const puri = getProxyUri()
  return uri.indexOf(puri) === -1
}

export const webSearchUrl = searchUrl.length ? searchUrl + '/web/' : '/search/web/'
export const ytSearchUrl = searchUrl.length ? searchUrl + '/yt/' : '/search/youtube/'
export const freesoundSearchUrl = searchUrl.length ? searchUrl + '/fs/' : '/search/freesound/'
export const wavefarmSearchUrl = searchUrl.length ? searchUrl + '/wf/' : '/search/wavefarm/'

export const isFreesoundUri = (uri: string): boolean => uri?.slice(0, 12) === 'freesound://'
export const freesoundIdFromUri = (uri: string): string => uri?.slice(12)
export const freesoundUriFromId = (id: number): string => 'freesound://' + id

export const isYoutubeUri = (uri: string): boolean => uri?.slice(0, 10) === 'youtube://'
export const ytIdFromUri = (uri: string): string => uri?.slice(10)
export const ytUriFromId = (videoId: string): string => 'youtube://' + videoId

export const isWavefarmUri = (uri: string): boolean => uri?.slice(0, 11) === 'wavefarm://'
export const wfUriFromId = (id: string): string => 'wavefarm://' + id

export const isArchiveUri = (uri: string): boolean => uri?.slice(0, 10) === 'archive://'
export const archiveUriFromId = (idAndName: string): string => 'archive://' + idAndName

export const isFileUri = (uri: string): boolean => uri?.slice(0, 7) === 'data://'
export const fileSha1FromUri = (uri: string): string => uri?.slice(7)
export const fileUriFromSha1 = (sha1: string): string => 'data://' + sha1

export const isRecUri = (uri: string): boolean => uri?.slice(0, 6) === 'rec://'
export const createNewRec = (buffer: ArrayBuffer, type: string): MediaBuffer => {
  const createdOn = new Date()
  return {
    uri: 'rec://' + createdOn.getTime(),
    title: createdOn.toString(),
    createdOn,
    buffer,
    type
  }
}

const bufferToHex = (buffer: ArrayBuffer) => Buffer.from(buffer).toString('hex')

const hasNeededApi = () => window.URL &&
  window.URL['createObjectURL'] &&
  window.crypto &&
  window.crypto.subtle

const fileToArrayBufferNoCheck = (file: File): Promise<Media> => new Promise((resolve, reject) => {
  const objUrl = window.URL.createObjectURL(file)
  const resHandler = new window.Response(file)
  const a = new window.Audio()
  a.onerror = () => {
    window.URL.revokeObjectURL(objUrl)
    return reject(new Error('Unable to open ' + file.name))
  }
  a.onloadedmetadata = () => {
    return resHandler.arrayBuffer()
      .then(buffer => window.crypto.subtle.digest('SHA-1', buffer)
        .then(hash => {
          const uri = fileUriFromSha1(bufferToHex(hash)) // uri format is data://<sha1>
          return dbPromise
            .then(db => db.put('buffers', ({
              uri,
              type: file.type,
              buffer,
              createdOn: new Date(),
              title: file.name,
              duration: a.duration || 0
            })))
            .then(() => uri)
        })
        .then((uri) => resolve({
          id: generateId(16),
          hasBuffer: true,
          isQueued: false,
          uri,
          title: file.name,
          size: file.size,
          type: file.type,
          duration: a.duration || 0
        }))
      )
      .finally(() => window.URL.revokeObjectURL(objUrl))
  }
  a.src = objUrl
})

export const fileToArrayBuffer = (file: File): Promise<Media> => hasNeededApi()
  ? fileToArrayBufferNoCheck(file)
  : Promise.reject(new Error('Your browser is missing needed functionality.  Please use a recent Firefox or Chrome browser.'))

const insertMedia = (playlist: Array<Media>, idx: number, media: Array<Media>) => {
  (playlist || []).splice(idx, 0, ...media)
  return playlist
}

const mergeMediaIntoPlaylist = (playlist: Array<Media>, medias: Array<Media>, idx?: number,): Array<Media> => idx === null || idx === undefined
  ? insertMedia(playlist, playlist.length, medias.map(m => Object.assign(m, { id: generateId(16) })))
  : insertMedia(playlist, idx, medias.map(m => Object.assign(m, { id: generateId(16) })))


export const addMediaAtIndex = (playlistId: string, media: Array<Media>, idx?: number): Promise<Array<Media>> => media?.length
  ? dbPromise
    .then(db => db.get('playlists', playlistId)
      .then(async pl => {
        const newMedia = mergeMediaIntoPlaylist(pl?.media || [], media, idx)
        const newPl = Object.assign({}, pl, { id: playlistId, media: newMedia, createdOn: pl?.createdOn || new Date(), updatedOn: new Date() })
        return db.put('playlists', newPl)
          .then(() => newPl.media)
      }))
  : Promise.reject(new Error('no media data'))

export const addFilesToPlaylist = (playlistId: string, files: FileList | Array<File>): Promise<Array<Media>> => Promise.all(Array.from(files)
  .map(fileToArrayBuffer))
  .then(media => addMediaAtIndex(playlistId, media))

export const decodeHTML = (html: string) => {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

const getUrl = (url: string, signal?: AbortSignal) => window.fetch(url, { method: 'get', signal })
  // always return json
  .then(r => r.json()
    .then(json => r.ok
      ? json
      : Promise.reject(new Error(json.error || r.statusText))
    )
  )

type YtInfo = {
  mimeType: string
  approxDurationMs: string
  contentLength: string
  url: string
}

type GetUrl = {
  src: string
  isBlob: boolean
  crossOrigin: string | null
}

const ytInfoUrl = searchUrl.length
  ? searchUrl + '/yt/info'
  : '/search/youtube/info'

const getYtInfo = (videoId: string, signal?: AbortSignal): Promise<YtInfo> =>
  getUrl(ytInfoUrl + '?videoId=' + encodeURIComponent(videoId), signal)

const ytInfoToYtt = (videoId: string, signal?: AbortSignal): Promise<YtTempUrl> => getYtInfo(videoId, signal)
  .then((d) => ({
    uri: 'youtube://' + videoId,
    tempUrl: d.url,
    tempTime: Date.now()
  }))

const ytCuttoffTimeInMs = 7.2e+6
const timeStillValid = (ytt: YtTempUrl): boolean => {
  const tt = ytt.tempTime || 0
  return Date.now() - tt < ytCuttoffTimeInMs // 7.2e+6
}

const getYtTempUrl = (signal: AbortSignal) => (m: PreviewMedia): Promise<GetUrl> => dbPromise
  .then(db => db.get('yttempurls', m.uri)
    .then(ytt => ytt && timeStillValid(ytt)
      ? ({ src: proxyUrl(ytt.tempUrl), isBlob: false, crossOrigin: null })
      : ytInfoToYtt(ytIdFromUri(m.uri), signal)
        .then(ytt => db.put('yttempurls', ytt)
          .then(() => ({ src: proxyUrl(ytt.tempUrl), isBlob: false, crossOrigin: null }))
        )
    ))

const getBlobUrl = (b: MediaBuffer): Promise<GetUrl> => {
  const blob = new window.Blob([b.buffer], { type: b.type })
  const src = window.URL.createObjectURL(blob)
  return Promise.resolve({ src, isBlob: true, crossOrigin: 'anonymous' })
}

const dbReg = /^https*:\/\/www.dropbox.com/
const getWavefarmUrl = (url: string) => dbReg.test(url)
  ? proxyUrl(url)
  : url
const getArchiveUrl = (url: string) => url.replace('archive://', 'https://archive.org/cors/')

const getNonBufferMediaUrl = (signal: AbortSignal, m: PreviewMedia): Promise<GetUrl> => {
  // if (isFileUri(m.uri)) return getMediaThenBlob(m)
  if (isYoutubeUri(m.uri)) return getYtTempUrl(signal)(m)
  if (isFreesoundUri(m.uri)) return Promise.resolve({ src: m.tempUrl || "", isBlob: false, crossOrigin: 'anonymous' })
  if (isWavefarmUri(m.uri)) return Promise.resolve({ src: getWavefarmUrl(m.tempUrl || ""), isBlob: false, crossOrigin: 'anonymous' })
  if (isArchiveUri(m.uri)) return Promise.resolve({ src: getArchiveUrl(m.uri), isBlob: false, crossOrigin: 'anonymous' })
  if (Object.hasOwn(m, 'uri')) return Promise.resolve({ src: m.uri, isBlob: false, crossOrigin: 'anonymous' })
  return Promise.reject(new Error('Media format not understood'))
}

export const getMediaUrl = (signal: AbortSignal) => (m: PreviewMedia): Promise<GetUrl> => dbPromise
  .then(db => db.get('buffers', m.uri))
  .then(buf => buf
    ? getBlobUrl(buf)
    : getNonBufferMediaUrl(signal, m)
  )

//const uniq = (value, index, array) => array.indexOf(value) === index
export const mapBufKeysToPlaylist = (playlist: Array<Media>, keys: Array<string>) => playlist.map(m => keys.indexOf(m.uri) !== -1
  ? Object.assign({}, m, { hasBuffer: true })
  : Object.assign({}, m, { hasBuffer: false })
)

type RangeCursor = IDBPCursorWithValue<DBv4, ['buffers'], 'buffers', 'uri', 'readonly'>
const regexCursor = (term: string) => (cursor: RangeCursor | null): Promise<Array<MediaBuffer>> => {
  const reg = new RegExp(term, 'i')
  const p: Array<MediaBuffer> = []
  function showRange(cursor: RangeCursor | null): Promise<void> {
    if (!cursor) { return Promise.resolve() }
    const v: MediaBuffer = cursor.value
    if (
      (v?.description && reg.test(v.description)) ||
      (v?.title && reg.test(v.title))
    ) {
      p.push(v)
    }
    return cursor.continue().then(showRange) // eslint-disable-line
  }
  return cursor
    ? showRange(cursor).then(() => p)
    : Promise.resolve([])
}

const searchDownloads = (term: string): Promise<Array<MediaBuffer>> => dbPromise
  .then(db => db.transaction('buffers', 'readonly')
    .objectStore('buffers')
    .index('uri')
    .openCursor()
    .then(regexCursor(term))
  )


const saveRecording = (blob: Blob) => blob.arrayBuffer()
  .then(async ab => {
    const rec = createNewRec(ab, blob.type)
    return dbPromise.then(db => db.put('buffers', rec)
      .then(() => rec)
    )
  })


const db = {
  playlists: {
    getById: (id: string): Promise<Playlist> => dbPromise.then(
      db => db.getAllKeys('buffers')
        .then(keys => db.get('playlists', id)
          .then(pl => pl
            ? Object.assign(pl, { media: mapBufKeysToPlaylist(pl?.media || [], keys || []) })
            : { id, createdOn: new Date(), modifiedOn: new Date(), media: [] }
          )
        )
    ),
    save: (playlistId: string, media: Array<Media>, display: string) => dbPromise
      .then(db => db.get('playlists', playlistId)
        .then(pl => db.put('playlists', Object.assign({}, pl, { media, display }))))
  },
  buffers: {
    getKeys: (): Promise<Array<string>> => dbPromise.then(db => db.getAllKeys('buffers')),
    search: searchDownloads,
    get: (uri: string) => dbPromise.then(db => db.get('buffers', uri)),
    put: (b: MediaBuffer) => dbPromise.then(db => db.put('buffers', b)),
    saveRecording
  }
}
export default db
